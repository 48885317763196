import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { userIsAdmin } from 'lib/helpers';

interface Props {
  jobId?: number;
  onCancel: () => void;
}

export const DeleteApplication = ({
  jobId, onCancel,
}: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const deleteApp = () => {
    if (!jobId) {
      return;
    }
    try {
      API.deleteJobApplication(jobId);
      enqueueSnackbar('Application deleted', {
        variant: 'success',
      });
      onCancel();
    } catch (error) {
      enqueueSnackbar(`Error loading data: ${error}`, {
        variant: 'error',
      });
    }
  };

  if (!jobId || !userIsAdmin(currentUser)) return null;

  return (
    <button
      type="button"
      className="btn-secondary-outlined my-2"
      onClick={deleteApp}
    >
      Delete application (admin)
    </button>
  );
};
