import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { useUpgradeModal, useTeamsUpgradeModal } from 'components/UpgradeModal';
import { ReactElement } from 'react';
import { userIsAdmin, userIsPro } from 'lib/helpers';
import { UserState } from 'lib/types/users';
import { isGondolaApp } from 'lib/utils/mobileAppUtils';
import { MobileTooltip } from 'components/MobileTooltip';
import { ProBadge } from 'components/ProBadge';

interface TooltipProps {
  children: ReactElement;
  title: string;
}

const userGetsTooltip = (user: UserState) => {
  if (userIsPro(user) || userIsAdmin(user)) {
    return true;
  }
  return false;
};

const TooltipForPro = ({ children, title }: TooltipProps) => {
  const currentUser = useCurrentUser();
  if (userGetsTooltip(currentUser)) {
    return (
      <MobileTooltip
        title={title}
        placement="top-end"
      >
        {children}
      </MobileTooltip>
    );
  }
  return <div>{children}</div>;
};

interface Props {
  /** The text displayed in the tooltip for Pros/Teams */
  title: string;
  /** The text displayed in the upsell dialog for non Pros/Teams */
  upgradeTitle: string;
  styling?: string;
  teamsFeature?: boolean;
}

/** Non pros/teams gets one tooltip with upsell dialog, Pros/teams get another tooltip */
export const FeaturedTag = ({
  title, styling, upgradeTitle, teamsFeature = false,
}: Props) => {
  const { showUpgradeModal } = useUpgradeModal();
  const { showTeamsUpgradeModal } = useTeamsUpgradeModal();
  const currentUser = useCurrentUser();

  const openDialog = () => {
    if (!isGondolaApp) {
      // We don't show the upgrade dialog in the Gondola app because we don't have in-app purchases.
      if (teamsFeature) {
        showTeamsUpgradeModal(upgradeTitle);
        return;
      }
      showUpgradeModal(upgradeTitle);
    }
  };

  return (
    <div>
      <TooltipForPro title={title}>
        <button
          type="button"
          onClick={() => (!userGetsTooltip(currentUser) ? openDialog() : null)}
          className={styling || 'absolute right-0'}
        >
          <ProBadge />
        </button>
      </TooltipForPro>
    </div>
  );
};
