import {
  JobType, JobPayType, JobLocationType, JobPayDisplay, AnswerType,
} from 'lib/types/jobs';

export const jobTypes: { [key in JobType]: string } = {
  ft: 'Full-time',
  pt: 'Part-time',
  gig: 'Gig',
  intern: 'Intern',
};

export const workTypes: { [key: string]: string } = {
  any: 'Any',
  ft: 'Full-time',
  pt: 'Freelancer',
};

export const jobLocationTypes: { [key in JobLocationType]: string } = {
  remote: 'Remote',
  hybrid: 'Hybrid',
  'on-site': 'On-site',
};

export const jobPayTypes: { [key in JobPayType]: { label: string; per: string } } = {
  hourly: { label: 'per hour', per: '/hr' },
  daily: { label: 'per day', per: '/day' },
  weekly: { label: 'per week', per: '/wk' },
  monthly: { label: 'per month', per: '/mth' },
  annually: { label: 'per year', per: '/yr' },
  'one-time': { label: 'one-time/project', per: '' },
};

export const jobPayDisplay: { [key in JobPayDisplay]: string } = {
  exact: 'Exact amount',
  range: 'Range',
  none: 'Not disclosed',
};

export const MaximumJobDescChars = 10000;

export const answerTypes: { [K in AnswerType]: string } = {
  text: 'Text',
  'single-select': 'Single Select',
  'multi-select': 'Multi Select',
};
