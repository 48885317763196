import { useState } from 'react';
import { MenuItem, Switch } from '@mui/material';
import { useRouter } from 'next/router';
import { Job } from 'lib/types/jobs';
import { buildJobPath } from 'lib/helpers';
import { useApi } from 'lib/contexts/ApplicationState';
import { DropdownMenu } from 'components/DropdownMenu';
import { SaveToProjectListButton } from 'components/SaveToList';

interface Props {
  job: Job;
}

export const JobCardMenu = ({
  job,
}: Props) => {
  const API = useApi();
  const [isFeatured, setIsFeatured] = useState(job.isFeatured);
  const router = useRouter();
  const jobPath = buildJobPath(job);

  const toggleIsFeatured = async () => {
    setIsFeatured(!isFeatured);
    await API.updateJob(job.id, { ...job, accountId: job.account.id, isFeatured: !isFeatured });
  };

  const goTo = (href: string) => {
    router.push(href);
  };

  return (
    <DropdownMenu>
      <MenuItem onClick={() => goTo(jobPath)}>
        View job details
      </MenuItem>
      <MenuItem onClick={toggleIsFeatured}>
        Feature job
        <Switch
          checked={isFeatured}
          name="isJobFeatured"
          color="primary"
        />
      </MenuItem>
      <MenuItem>
        <SaveToProjectListButton itemId={job.id} teamId={job.teamId} itemType="Job" context="list" />
        Save to project list
      </MenuItem>
    </DropdownMenu>
  );
};
