import { jobPayTypes } from 'lib/constants/jobs';
import { JobPayDisplay, JobPayType } from 'lib/types/jobs';

export const addCommas = (n?: string|number) => {
  if (!n) return undefined;

  const parts = n.toString().split('.');
  parts[0] = parts[0].replace(/,/, '');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const removeCommas = (n?: string) => Number(n?.replace(/,/g, ''));

export const formatCents = (n?: number) => {
  if (!n) return '';

  return addCommas(Number(n / 100).toFixed(2));
};

export const getPayInfo = (
  payType: JobPayType,
  payDisplay: JobPayDisplay,
  payAmount?: number,
  payMin?: number,
  payMax?: number,
) => {
  if (payDisplay === 'range') {
    if (!payMin && !payMax) {
      return 'Pay not disclosed';
    } if (!payMin) {
      return `Up to $${addCommas(payMax)}${jobPayTypes[payType].per}`;
    } if (!payMax) {
      return `From $${addCommas(payMin)}${jobPayTypes[payType].per}`;
    }
    return `$${(addCommas(payMin))} - $${addCommas(payMax)}${jobPayTypes[payType]?.per}`;
  }
  if (!payAmount) {
    return 'Pay not disclosed';
  }
  return `$${addCommas(payAmount)}${jobPayTypes[payType]?.per}`;
};
