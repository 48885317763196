import { TeamMemberRole, TeamStatus } from 'lib/types/teams';

export const ROLE_MANAGER: TeamMemberRole = 'manager';
export const ROLE_MEMBER: TeamMemberRole = 'member';
export const ROLE_PRO_ONLY: TeamMemberRole = 'pro_only';

/** Roles that have full team priviledges (not PRO-only) */
export const FULL_TEAM_ROLES = [ROLE_MANAGER, ROLE_MEMBER];

export const TEAM_ROLE = {
  manager: ROLE_MANAGER,
  member: ROLE_MEMBER,
  proOnly: ROLE_PRO_ONLY,
};

export const STATUS_ACTIVE: TeamStatus = 'active';
export const STATUS_INACTIVE: TeamStatus = 'inactive';
export const STATUS_INVITED: TeamStatus = 'invited';

export const TEAM_STATUS = {
  active: STATUS_ACTIVE,
  inactive: STATUS_INACTIVE,
  invited: STATUS_INVITED,
};

/** The name given to "Work For Us" jobs and lists */
export const WORK_FOR_US_NAME = 'Prospects';
export const WORK_FOR_US_DESCRIPTION = 'Join our team!  By applying, you will be added to the prospects list.';
